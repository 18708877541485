/*
 * @Description:
 * @Version: 2.0
 * @Autor: libo
 * @Date: 2021-09-09 14:10:15
 * @LastEditors: libo
 * @LastEditTime: 2021-09-09 15:24:45
 */
import {
  MUTATIONS_PARK3D__PERSONNEL_LIST,
  MUTATIONS_PARK3D__PERSONNEL_BASIC,
  MUTATIONS_PARK3D__PERSONNEL_STAFF_DISTRIBUTION,
  MUTATIONS_PARK3D__PERSONNEL_PARAM,
  MUTATIONS_PARK3D__PERSONNEL_INFO,
  MUTATIONS_PARK3D__PERSONNEL_VIP_THROUGH_RECORD,
  MUTATIONS_PARK3D__PERSONNEL_INSIDE_THROUGH_RECORD,
  MUTATIONS_PARK3D__PERSONNEL_SANS_THROUGH_RECORD
} from "../mutations_type";
const personData = {
  state: () => {
    return {
      // 搜索人员
      personnelList: [
        {
          age: 0,
          buildingId: 0,
          category: "",
          code: "",
          company: 0,
          createDept: 0,
          createTime: "",
          createUser: 0,
          floorId: 0,
          id: 0,
          idCard: "",
          imgsrc: "",
          isDeleted: 0,
          license: "",
          name: "",
          parkId: 0,
          phone: "",
          roomId: 0,
          sex: "",
          snStatus: "",
          status: 0,
          tenantId: "",
          type: "",
          updateTime: "",
          updateUser: 0
        }
      ],
      // 人员基本信息
      personnelBasic: {
        parkStaff: {
          insiderNumber: 0,
          employeesNumber: 0
        },
        sexRatioInfo: [
          {
            key: "男",
            value: "3",
            memo: ""
          },
          {
            key: "女",
            value: "3",
            memo: ""
          },
          {
            key: "未知",
            value: "10",
            memo: ""
          }
        ],
        ageRatioInfo: [
          {
            key: "18岁以下",
            value: "3",
            memo: ""
          },
          {
            key: "18-25岁",
            value: "0",
            memo: ""
          },
          {
            key: "26-30岁",
            value: "0",
            memo: ""
          },
          {
            key: "31-35岁",
            value: "0",
            memo: ""
          },
          {
            key: "36-45岁",
            value: "0",
            memo: ""
          },
          {
            key: "46-55岁",
            value: "0",
            memo: ""
          },
          {
            key: "55岁以上",
            value: "0",
            memo: ""
          },
          {
            key: "未知",
            value: "13",
            memo: ""
          }
        ]
      },

      // 人员分布
      staffDistribution: [],
      // 访客去向
      personnelParam: {
        peopleMakeForParkMonth: [],
        peopleMakeForParkDay: []
      },

      // 进出人员信息
      personnelInfo: {
        dubiousCountDay: [],
        dubiousCountMonth: [],
        enterParkCountDay: [],
        enterParkCountMonth: [],
        visitorCountDay: [],
        visitorCountMonth: []
      },

      // VIP人员通行记录
      vipThroughRecord: [
        {
          buildingId: 0,
          cameraIndexCode: "",
          cameraName: "",
          captureBkgUrl: "",
          category: "",
          code: "",
          company: 0,
          companyName: 0,
          createDept: 0,
          createTime: "",
          createUser: 0,
          deviceName: "",
          equipmentName: "",
          facePhotoSrc: "",
          floorId: 0,
          glass: "",
          id: 0,
          idCard: "",
          imgSrc: "",
          isDeleted: 0,
          license: "",
          mask: "",
          name: "",
          parkId: 0,
          personId: "",
          phone: "",
          productKey: "",
          roomId: 0,
          score: 0,
          setPosition: "",
          sex: "",
          sn: "",
          status: 0,
          temperature: 0,
          tenantId: "",
          timeStamp: 0,
          type: "",
          updateTime: "",
          updateUser: 0
        }
      ],

      // 内部人员通行记录
      insideThroughRecord: [
        {
          buildingId: 0,
          cameraIndexCode: "",
          cameraName: "",
          captureBkgUrl: "",
          category: "",
          code: "",
          company: 0,
          companyName: 0,
          createDept: 0,
          createTime: "",
          createUser: 0,
          deviceName: "",
          equipmentName: "",
          facePhotoSrc: "",
          floorId: 0,
          glass: "",
          id: 0,
          idCard: "",
          imgSrc: "",
          isDeleted: 0,
          license: "",
          mask: "",
          name: "",
          parkId: 0,
          personId: "",
          phone: "",
          productKey: "",
          roomId: 0,
          score: 0,
          setPosition: "",
          sex: "",
          sn: "",
          status: 0,
          temperature: 0,
          tenantId: "",
          timeStamp: 0,
          type: "",
          updateTime: "",
          updateUser: 0
        }
      ],

      // 外部访客通行记录
      sansThroughRecord: [
        {
          buildingId: 0,
          cameraIndexCode: "",
          cameraName: "",
          captureBkgUrl: "",
          category: "",
          code: "",
          company: 0,
          companyName: 0,
          createDept: 0,
          createTime: "",
          createUser: 0,
          deviceName: "",
          equipmentName: "",
          facePhotoSrc: "",
          floorId: 0,
          glass: "",
          id: 0,
          idCard: "",
          imgSrc: "",
          isDeleted: 0,
          license: "",
          mask: "",
          name: "",
          parkId: 0,
          personId: "",
          phone: "",
          productKey: "",
          roomId: 0,
          score: 0,
          setPosition: "",
          sex: "",
          sn: "",
          status: 0,
          temperature: 0,
          tenantId: "",
          timeStamp: 0,
          type: "",
          updateTime: "",
          updateUser: 0
        }
      ]
    };
  },
  mutations: {
    [MUTATIONS_PARK3D__PERSONNEL_LIST](state, val) {
      state.personnelList = val;
    },

    [MUTATIONS_PARK3D__PERSONNEL_BASIC](state, val) {
      state.personnelBasic = val;
    },

    [MUTATIONS_PARK3D__PERSONNEL_STAFF_DISTRIBUTION](state, val) {
      state.staffDistribution = val;
    },

    [MUTATIONS_PARK3D__PERSONNEL_PARAM](state, val) {
      state.personnelParam = val;
    },

    [MUTATIONS_PARK3D__PERSONNEL_INFO](state, val) {
      state.personnelInfo = val;
    },

    [MUTATIONS_PARK3D__PERSONNEL_VIP_THROUGH_RECORD](state, val) {
      state.vipThroughRecord = val;
    },

    [MUTATIONS_PARK3D__PERSONNEL_INSIDE_THROUGH_RECORD](state, val) {
      state.insideThroughRecord = val;
    },

    [MUTATIONS_PARK3D__PERSONNEL_SANS_THROUGH_RECORD](state, val) {
      state.sansThroughRecord = val;
    }
  },
  actions: {
    // 人员-搜索人员
    fn_api_get_park_personnelList({ commit }, data) {
      commit(MUTATIONS_PARK3D__PERSONNEL_LIST, data);
    },

    // 人员-人员基本信息
    fn_api_get_park_personnelBasic({ commit }, data) {
      commit(MUTATIONS_PARK3D__PERSONNEL_BASIC, data);
    },

    // 人员-人员分布
    fn_api_get_park_personnel_staffDistribution({ commit }, data) {
      commit(MUTATIONS_PARK3D__PERSONNEL_STAFF_DISTRIBUTION, data);
    },

    // 人员-访客去向
    fn_api_get_park_personnelParam({ commit }, data) {
      commit(MUTATIONS_PARK3D__PERSONNEL_PARAM, data);
    },

    // 人员-进出人员信息
    fn_api_get_park_personnelInfo({ commit }, data) {
      commit(MUTATIONS_PARK3D__PERSONNEL_INFO, data);
    },

    // 人员-人员基本信息
    fn_api_get_park_personnelVip_throughRecord({ commit }, data) {
      commit(MUTATIONS_PARK3D__PERSONNEL_VIP_THROUGH_RECORD, data);
    },

    // 人员-人员基本信息
    fn_api_get_park_personnelInside_throughRecord({ commit }, data) {
      commit(MUTATIONS_PARK3D__PERSONNEL_INSIDE_THROUGH_RECORD, data);
    },

    // 人员-人员基本信息
    fn_api_get_park_personnelSans_throughRecord({ commit }, data) {
      commit(MUTATIONS_PARK3D__PERSONNEL_SANS_THROUGH_RECORD, data);
    }
  }
};
export default personData;
