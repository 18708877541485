/*
 * @Description: 3d模型数据
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-08-13 17:58:50
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:42:12
 */
import {
  MUTATIONS_PARK3D_SECURITY_BEHAVIOR,
  MUTATIONS_PARK3D_SECURITY_DISTRIBUTION,
  MUTATIONS_PARK3D_SECURITY_ABNORMAL_LIST,
  MUTATIONS_PARK3D_SECURITY_DEVICE_COUNT,
  MUTATIONS_PARK3D_SECURITY_DEVICE_LIST,
  MUTATIONS_PARK3D_SECURITY_DEVICE_DISTRIBUTION,
  MUTATIONS_PARK3D_SECURITY_ALARM_LIST
} from '../mutations_type'
const securityData = {
  state: () => {
    return {
      // 安防异常行为趋势
      securityBehavior: {
        abnormalBehaviorToday: [
          {
            abscissa: 1,
            ordinate: 0
          }
        ],
        abnormalBehaviorMonth: [
          {
            abscissa: 1,
            ordinate: 1389
          }
        ]
      },

      // 安防异常行为分布
      securityAbnormalDistribution: {
        distributionMonth: [],
        distributionToday: []
      },

      // 安防异常行为列表
      securityAbnormalList: [],

      // 安防设备数
      securityDeviceCount: {
        total: 33,
        totalOnline: 3,
        totalOffline: 30,
        totalFault: 0,
        percentageOnline: '9.09%'
      },
      // 安防设备列表
      securityDeviceList: [],

      // 安防设备分布
      securityDeviceDistribution: [],

      // 安防告警列表
      securityAlarmList: []
    }
  },
  mutations: {
    [MUTATIONS_PARK3D_SECURITY_BEHAVIOR](state, val) {
      console.log('安防异常行为趋势', val)
      state.securityBehavior = val
    },

    [MUTATIONS_PARK3D_SECURITY_DISTRIBUTION](state, val) {
      console.log('安防异常行为分布', val)
      state.securityAbnormalDistribution = val
    },

    [MUTATIONS_PARK3D_SECURITY_ABNORMAL_LIST](state, val) {
      console.log('安防异常行为列表', val)
      state.securityAbnormalList = val
    },

    [MUTATIONS_PARK3D_SECURITY_DEVICE_COUNT](state, val) {
      console.log('安防设备数', val)
      state.securityDeviceCount = val
    },
    [MUTATIONS_PARK3D_SECURITY_DEVICE_LIST](state, val) {
      console.log('安防设备列表', val)
      state.securityDeviceList = val
    },
    [MUTATIONS_PARK3D_SECURITY_DEVICE_DISTRIBUTION](state, val) {
      console.log('安防设备分布', val)
      state.securityDeviceDistribution = val
    },
    [MUTATIONS_PARK3D_SECURITY_ALARM_LIST](state, val) {
      console.log('安防告警列表', val)
      state.securityAlarmList = val
    }
  },
  actions: {
    // 安防异常行为趋势
    fn_api_get_park_securityBehavior({ commit }, data) {
      commit(MUTATIONS_PARK3D_SECURITY_BEHAVIOR, data)
    },
    // 安防异常行为分布
    fn_api_get_park_securityDistribution({ commit }, data) {
      commit(MUTATIONS_PARK3D_SECURITY_DISTRIBUTION, data)
    },
    // 安防异常行为列表
    fn_api_get_park_securityAbnormalList({ commit }, data) {
      commit(MUTATIONS_PARK3D_SECURITY_ABNORMAL_LIST, data)
    },
    // 安防设备数
    fn_api_get_park_securityDeviceCount({ commit }, data) {
      commit(MUTATIONS_PARK3D_SECURITY_DEVICE_COUNT, data)
    },
    // 安防设备列表
    fn_api_get_park_securityDeviceList({ commit }, data) {
      commit(MUTATIONS_PARK3D_SECURITY_DEVICE_LIST, data)
    },
    // 安防设备分布
    fn_api_get_park_securityDeviceDistribution({ commit }, data) {
      commit(MUTATIONS_PARK3D_SECURITY_DEVICE_DISTRIBUTION, data)
    },
    // 安防告警列表
    fn_api_get_park_securityAlarmList({ commit }, data) {
      commit(MUTATIONS_PARK3D_SECURITY_ALARM_LIST, data)
    }
  }
}
export default securityData
