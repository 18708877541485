/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-09 20:06:31
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:20

 */

export const MUTATIONS_USER__SET_TOKEN = 'MUTATIONS_USER__SET_TOKEN'
export const MUTATIONS_USER__SET_REFRESHTOKEN =
  'MUTATIONS_USER__SET_REFRESHTOKEN'
export const MUTATIONS_USER__SET_USERINFO = 'MUTATIONS_USER__SET_USERINFO'
export const MUTATIONS_USER__REFRESHTOKEN = 'MUTATIONS_USER__REFRESHTOKEN'
export const MUTATIONS_USER__CLEAR = 'MUTATIONS_USER__CLEAR'

// 图片预览
export const MUTATIONS_PARK3D__PREVIEWIMAGES =
  'MUTATIONS_PARK3D__PREVIEWIMAGES'

// 屏幕缩放
export const MUTATIONS_PARK3D__ZOOM = 'MUTATIONS_PARK3D__ZOOM'

// 视屏控件索引
export const MUTATIONS_PARK3D__MONITOR_OWEBCONTROL =
  'MUTATIONS_PARK3D__MONITOR_OWEBCONTROL'

// 首页信息
export const MUTATIONS_PARK3D__PARK_INFO = 'MUTATIONS_PARK3D__PARK_INFO'

// 首页切换楼栋信息
export const MUTATIONS_PARK3D__PARK_BUILDING_INFO = 'MUTATIONS_PARK3D__PARK_BUILDING_INFO'

// 首页切换楼层信息
export const MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_INFO = 'MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_INFO'

// 停车场信息
export const MUTATIONS_PARK3D__PARKING_INFO = 'MUTATIONS_PARK3D__PARKING_INFO'

// 环境详情
export const MUTATIONS_PARK3D__PARK_ENVIRON_DETAIL =
  'MUTATIONS_PARK3D__PARK_ENVIRON_DETAIL'

// 设备详情
export const MUTATIONS_PARK3D__PARK_DEVICE_DETAIL =
  'MUTATIONS_PARK3D__PARK_DEVICE_DETAIL'

// 人员详情
export const MUTATIONS_PARK3D__PARK_PEOPLES_DETAIL =
  'MUTATIONS_PARK3D__PARK_PEOPLES_DETAIL'

// 告警详情
export const MUTATIONS_PARK3D__PARK_ALARM_DETAIL =
  'MUTATIONS_PARK3D__PARK_ALARM_DETAIL'

// 车辆信息详情
export const MUTATIONS_PARK3D__PARK_PARKING_DETAIL =
  'MUTATIONS_PARK3D__PARK_PARKING_DETAIL'

// 能耗详情
export const MUTATIONS_PARK3D__PARK_ENERGY_DETAIL =
  'MUTATIONS_PARK3D__PARK_ENERGY_DETAIL'

// 楼栋、楼层信息
export const MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_TREE_DATA =
  'MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_TREE_DATA'

// -------------------------------------------------------- 人员模块 ---------------------------------------------------

// 搜索人员
export const MUTATIONS_PARK3D__PERSONNEL_LIST =
  'MUTATIONS_PARK3D__PERSONNEL_LIST'

// 人员基本信息
export const MUTATIONS_PARK3D__PERSONNEL_BASIC =
  'MUTATIONS_PARK3D__PERSONNEL_BASIC'

// 人员分布
export const MUTATIONS_PARK3D__PERSONNEL_STAFF_DISTRIBUTION =
  'MUTATIONS_PARK3D__PERSONNEL_STAFF_DISTRIBUTION'

// 访客去向
export const MUTATIONS_PARK3D__PERSONNEL_PARAM =
  'MUTATIONS_PARK3D__PERSONNEL_PARAM'

// 进出人员信息
export const MUTATIONS_PARK3D__PERSONNEL_INFO =
  'MUTATIONS_PARK3D__PERSONNEL_INFO'

// VIP人员通行记录
export const MUTATIONS_PARK3D__PERSONNEL_VIP_THROUGH_RECORD =
  'MUTATIONS_PARK3D__PERSONNEL_VIP_THROUGH_RECORD'

// 内部人员通行记录
export const MUTATIONS_PARK3D__PERSONNEL_INSIDE_THROUGH_RECORD =
  'MUTATIONS_PARK3D__PERSONNEL_INSIDE_THROUGH_RECORD'

// 外部人员通行记录
export const MUTATIONS_PARK3D__PERSONNEL_SANS_THROUGH_RECORD =
  'MUTATIONS_PARK3D__PERSONNEL_SANS_THROUGH_RECORD'

// // 人员行为异常列表
// export const MUTATIONS_PARK3D__ABNORMAL_LIST =
//   "MUTATIONS_PARK3D__ABNORMAL_LIST";

// 能耗设备数
export const MUTATIONS_PARK3D__ENERGY_DEVICE_COUNT =
  'MUTATIONS_PARK3D__ENERGY_DEVICE_COUNT'
// 能耗告警列表
export const MUTATIONS_PARK3D__ENERGY_ALARMLIST =
  'MUTATIONS_PARK3D__ENERGY_ALARMLIST'
// 能耗设备列表
export const MUTATIONS_PARK3D__ENERGY_DEVICELIST =
  'MUTATIONS_PARK3D__ENERGY_DEVICELIST'
// 能耗设备分布
export const MUTATIONS_PARK3D__ENERGY_DEVICEDISTRIBUTIONLIST =
  'MUTATIONS_PARK3D__ENERGY_DEVICEDISTRIBUTIONLIST'

// ------------------------------车辆模块列表信息-------------------------------

// 特殊车辆通行记录
export const MUTATIONS_PARK3D__PARKING_GROUPTRANSITRECORD =
  'MUTATIONS_PARK3D__PARKING_GROUPTRANSITRECORD'

// 月卡车辆通行记录
export const MUTATIONS_PARK3D__PARKING_MONTHLYCARDTRANSITRECORD =
  'MUTATIONS_PARK3D__PARKING_MONTHLYCARDTRANSITRECORD'

// 临停车辆通行记录
export const MUTATIONS_PARK3D__PARKING_TEMPTRANSITRECORD =
  'MUTATIONS_PARK3D__PARKING_TEMPTRANSITRECORD'

/**
 * @desc basicThree 加载状态
 */
export const MUTATIONS_PARK3D__BASICTHREE_HAVELOADED =
  'MUTATIONS_PARK3D__BASICTHREE_HAVELOADED'

/**
 * @desc basicThree 场景状态
 */
export const MUTATIONS_PARK3D__BASICTHREE_BUILDING_STATUS =
  'MUTATIONS_PARK3D__BASICTHREE_BUILDING_STATUS'

// 模型等级
export const MUTATIONS_PARK3D__BASICTHREE_MODEL_LEVEL =
  'MUTATIONS_PARK3D__BASICTHREE_MODEL_LEVEL'
// 楼栋名称唯一标识
export const MUTATIONS_PARK3D__BASICTHREE_BUILDING_NAME =
  'MUTATIONS_PARK3D__BASICTHREE_BUILDING_NAME'
// 楼层名称唯一标识
export const MUTATIONS_PARK3D__BASICTHREE_FLOORNAME_NAME =
  'MUTATIONS_PARK3D__BASICTHREE_FLOORNAME_NAME'
// 停车场名称唯一标识
export const MUTATIONS_PARK3D__BASICTHREE_PARIKING_NAME =
  'MUTATIONS_PARK3D__BASICTHREE_PARIKING_NAME'
// 设置楼层与模型联动
export const MUTATIONS_PARK3D__BASICTHREE_SWIPER_INDEX =
  'MUTATIONS_PARK3D__BASICTHREE_SWIPER_INDEX'

// ------------------------------------设备模块--------------------------------------
export const MUTATIONS_PARK3D_PARKING_DEVICE_EQUIPINFO =
  'MUTATIONS_PARK3D_PARKING_DEVICE_EQUIPINFO'

// ------------------------------------环境模块--------------------------------------
// 环境设备数
export const MUTATIONS_PARK3D__ENVIRONMENT_DEVICE_COUNT =
  'MUTATIONS_PARK3D__ENVIRONMENT_DEVICE_COUNT'
// 环境设备图表
export const MUTATIONS_PARK3D__ENVIRONMENT_GROUP_COUNT =
  'MUTATIONS_PARK3D__ENVIRONMENT_GROUP_COUNT'
// 环境变化趋势
export const MUTATIONS_PARK3D__ENVIRONMENT_CHANGE =
  'MUTATIONS_PARK3D__ENVIRONMENT_CHANGE'
// 环境告警
export const MUTATIONS_PARK3D__ENVIRONMENT_ALARM =
  'MUTATIONS_PARK3D__ENVIRONMENT_ALARM'
// 环境设备列表
export const MUTATIONS_PARK3D__ENVIRONMENT_FACILITYLIST =
  'MUTATIONS_PARK3D__ENVIRONMENT_FACILITYLIST'
// 环境检测信息
export const MUTATIONS_PARK3D__ENVIRONMENT_DETECTIONINFO =
  'MUTATIONS_PARK3D__ENVIRONMENT_DETECTIONINFO'

// ------------------------------------安防----------------------------------------

// 安防异常行为趋势
export const MUTATIONS_PARK3D_SECURITY_BEHAVIOR =
  'MUTATIONS_PARK3D_SECURITY_BEHAVIOR'

// 安防异常行为分布
export const MUTATIONS_PARK3D_SECURITY_DISTRIBUTION =
  'MUTATIONS_PARK3D_SECURITY_DISTRIBUTION'

// 安防异常行为列表
export const MUTATIONS_PARK3D_SECURITY_ABNORMAL_LIST =
  'MUTATIONS_PARK3D_SECURITY_ABNORMAL_LIST'

// 安防设备数
export const MUTATIONS_PARK3D_SECURITY_DEVICE_COUNT =
  'MUTATIONS_PARK3D_SECURITY_DEVICE_COUNT'

// 安防设备列表
export const MUTATIONS_PARK3D_SECURITY_DEVICE_LIST =
  'MUTATIONS_PARK3D_SECURITY_DEVICE_LIST'

// 安防设备分布
export const MUTATIONS_PARK3D_SECURITY_DEVICE_DISTRIBUTION =
  'MUTATIONS_PARK3D_SECURITY_DEVICE_DISTRIBUTION'

// 安防告警列表
export const MUTATIONS_PARK3D_SECURITY_ALARM_LIST =
  'MUTATIONS_PARK3D_SECURITY_ALARM_LIST'

// ------------------------------------消防----------------------------------------

// 消防设备数
export const MUTATIONS_PARK3D_FIRE_DEVICE_COUNT =
  'MUTATIONS_PARK3D_FIRE_DEVICE_COUNT'

// 消防设备分类
export const MUTATIONS_PARK3D_FIRE_GROUP_COUNT =
  'MUTATIONS_PARK3D_FIRE_GROUP_COUNT'

// 消防设备列表
export const MUTATIONS_PARK3D_FIRE_DEVICE_LIST =
  'MUTATIONS_PARK3D_FIRE_DEVICE_LIST'

// 消防设备分布
export const MUTATIONS_PARK3D_FIRE_DEVICE_DISTRIBUTION =
  'MUTATIONS_PARK3D_FIRE_DEVICE_DISTRIBUTION'

// 消防告警列表
export const MUTATIONS_PARK3D_FIRE_ALARM_LIST =
  'MUTATIONS_PARK3D_FIRE_ALARM_LIST'

// 消防火警统计信息
export const MUTATIONS_PARK3D_FIRE_STAT_INFO =
  'MUTATIONS_PARK3D_FIRE_STAT_INFO'

// 消防火警列表
export const MUTATIONS_PARK3D_FIRE_LIST = 'MUTATIONS_PARK3D_FIRE_LIST'

//  首页告警列表  /  设备告警列表  独立接口
export const MUTATIONS_PARK3D__PARK_ALARMLIST =
  'MUTATIONS_PARK3D__PARK_ALARMLIST'
