/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-29 16:25:34
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:42:14
 */

const tags = {
  state: () => {
    return {

    }
  },
  mutations: {

  },
  actions: {

  }
}
export default tags
